import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEO"

import useTranslation from "../translations/translate"

const Imprint = ({ pageContext: { pageName } }) => {
  const { t, getLocalizedPath } = useTranslation()

  return (
    <>
      <SEO
        title="Imprint"
        description="Der Schutz Ihrer Daten hat beim Besuchen unserer Website höchste Priorität."
        pageName={pageName}
        noIndex
      />
      <section className="container content d-flex">
        <div className="row w-100 my-auto flex-column-reverse flex-md-row">
          <div className="col-12">
            <div className="mb-5">
              <Link to={getLocalizedPath("/")} className="text-decoration-none">
                ← {t("common.back")}
              </Link>
            </div>

            <h1 className="mb-5">{t("page.imprint.title")}</h1>

            <p className="lead">
              <span className="fw-bold">According to § 5 TMG</span>
              <br />
              EVOBEND
              <br />
              Am Schmidinger Weiher 3<br />
              D-83404 Ainring
            </p>

            <p className="lead">
              <span className="fw-bold">Represented by</span>
              <br />
              Emanuel Allerberger
            </p>

            <p className="lead">
              <span className="fw-bold">Contact</span>
              <br />
              Phone: +49 157 8302 1032
              <br />
              E-Mail: info@evobend.com
              <br />
              www.evobend.com
            </p>

            <p className="lead">
              <span className="fw-bold">Register entry</span>
              <br />
              Registration in the commercial register.
              <br />
              Commercial register: HR B 26754
              <br />
              Register court: Traunstein
            </p>

            <p className="lead">
              <span className="fw-bold">Value added tax</span>
              <br />
              Value added tax identification number according § 27 a
              <br />
              Value added tax identification number: DE 316 813 238
            </p>

            <p className="lead">
              <span className="fw-bold">Dispute settlement</span>
              <br />
              We are neither willing nor obliged to take part in dispute
              settlement proceedings before a consumer arbitration board.
            </p>

            <p className="lead">
              <span className="fw-bold">Liability for content</span>
              <br />
              As a service provider, we are responsible for our own content on
              these pages in accordance with Section 7, Paragraph 1 of the
              German Telemedia Act. According to §§ 8 to 10 TMG, as a service
              provider, we are not obliged to monitor transmitted or stored
              third-party information or to investigate circumstances that
              indicate illegal activity. Obligations to remove or block the use
              of information under general law remain unaffected. Liability in
              this regard is only possible from the point in time at which we
              become aware of a specific legal violation. As soon as we become
              aware of such legal violations, we will remove this content
              immediately.
            </p>

            <p className="lead">
              <span className="fw-bold">Liability for links</span>
              <br />
              Our offer contains links to external third-party websites over
              whose content we have no influence. Therefore we cannot accept any
              liability for this external content. The respective provider or
              operator of the pages is always responsible for the content of the
              linked pages. The linked pages were checked for possible legal
              violations at the time they were linked. No illegal content was
              found at the time the link was created. However, permanent
              monitoring of the content of the linked pages is not reasonable
              without concrete evidence of a violation of the law. If we become
              aware of legal violations, we will remove such links immediately.
            </p>

            <p className="lead">
              <span className="fw-bold">Copyright</span>
              <br />
              The content and works on these pages created by the website
              operator are subject to German copyright law. The duplication,
              processing, distribution and any kind of exploitation outside the
              limits of copyright law require the written consent of the
              respective author or creator. Downloads and copies of this website
              are only permitted for private, non-commercial use. Insofar as the
              content on this site was not created by the operator, the
              copyrights of third parties are observed. In particular contents
              of third parties are marked as such. Should you nevertheless
              become aware of a copyright infringement, we would ask you to
              notify us accordingly. As soon as we become aware of legal
              violations, we will remove such content immediately.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Imprint
